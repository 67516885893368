@import-normalize; /* bring in normalize.css styles */

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'TeX Gyre Adventor', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.5;
  min-width: 320px;
}

code {
  font-family: monospace;
}

@media (min-width: 1200px) {
  body {
    font-size: 1.125rem; /* 18px */
  }
}
