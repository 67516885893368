.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #FFF;
  min-width: 320px;
  color: #444444;
}

.page * {
  box-sizing: border-box;
}

.page-content {
  flex-grow: 1;
}

.container,
.container-wide {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .container,
  .container-wide {
    padding-left: 34px;
    padding-right: 34px;
  }

  .container {
    max-width: 1238px; /* 1170 + 34 * 2 */
  }
}
