@font-face {
  font-family: 'TeX Gyre Adventor';
  src: url('../fonts/texgyreadventor-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TeX Gyre Adventor';
  src: url('../fonts/texgyreadventor-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
