.icon-start {
  display: inline-flex;
  align-items: center;
  padding-left: 32px;
  position: relative;
}

.icon-start .icon {
  position: absolute;
  left: 0;
}
