.page-footer__section--top {
  background: #001e60;
  color: #FFF;
  font-weight: bold;
  letter-spacing: 0.2px;
}

.page-footer__section--top .page-footer__section-container,
.page-footer__section--bottom .page-footer__section-container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.page-footer__section--top a,
.page-footer__section--top a:visited {
  color: #e87722;
}

.page-footer__section--top a:hover,
.page-footer__section--top a:active {
  color: #fc9d6f;
}

.page-footer__section--top .page-footer__section-container {
  display: flex;
  flex-direction: column;
  gap: 16px 20px;
}

.page-footer .contact-item__title {
  margin-bottom: 8px;
}

.page-footer .contact-item__actions {
  display: flex;
  flex-direction: column;
  gap: 8px 24px;
}

.page-footer__section--center .page-footer__section-container {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.page-footer__logo-group {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.page-footer__menu-group {
  display: flex;
  flex-direction: column;
  gap: 16px 24px;
  font-size: 1rem;
  line-height: 1.5;
}

.page-footer .menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-footer .menu a {
  display: block;
  font-weight: normal;
  color: #444;
  padding: 8px 0;
}

.page-footer .menu a:hover {
  color: #000;
}

.page-footer__partner-image {
  display: block;
  height: 46px;
  width: auto;
}

.page-footer__social {
  display: flex;
  gap: 24px;
}

.page-footer .social-item a {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.page-footer__social-icon {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.page-footer .social-item--ig a {
  background-image: linear-gradient(44deg, #fdcd61 15%, #d5327d 50%, #575acf 87%);
}

.page-footer .social-item--fb a {
  background: #3b5998;
}

.page-footer .social-item--tw a {
  background: #00acee;
}

.page-footer .social-item--yt a {
  background: #c4302b;
}

.page-footer__section--bottom {
  background: #e7eaf1;
  font-size: .875rem; /* 14px */
  letter-spacing: 0.16px;
}

@media (min-width: 768px) {
  .page-footer .contact-item__actions {
    flex-direction: row;
  }

  .page-footer__logo-group {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .page-footer__menu-group {
    flex-direction: row;
  }

  .page-footer__menu-group .menu {
    flex: 1 1 0;
  }

  .page-footer__partner-image {
    height: 54px;
  }
}

@media (min-width: 1200px) {
  .page-footer__section--top .page-footer__section-container {
    padding-top: 24px;
    padding-bottom: 24px;
    flex-direction: row;
  }

  .page-footer__section--center .page-footer__section-container {
    padding-top: 56px;
    padding-bottom: 56px;
    flex-direction: row;
  }

  .page-footer__logo-group {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 56px;
    flex: 0 0 auto;
    width: 268px; /* 300 - 32 */
  }

  .page-footer__menu-group {
    flex: 0 0 auto;
    width: calc(100% - 300px);
  }

  .page-footer .menu {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  .page-footer .contact-item {
    flex: 1 1 0;
  }
}
