a {
  color: #d4431c;
  font-weight: bold;
  text-decoration: underline;
  word-wrap: break-word;
}

a:visited {
  color: #d4431c;
}

a:hover,
a:active {
  color: #b42813;
}

p {
  line-height: 1.5;
  letter-spacing: 0.3px;
  margin-top: 0;
  margin-bottom: 12px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.38;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #001e60;
  margin-top: 0;
  margin-bottom: 12px;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.75rem;
}

@media (min-width: 1200px) {
  p {
    line-height: 1.56;
    margin-bottom: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 16px;
  }

  h1 {
    font-size: 3.5rem;
    line-height: 1.14;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }
}

