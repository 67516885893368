.page-content__container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.page-content__text-group {
  max-width: 733px;
}

@media (min-width: 1200px) {
  .page-content__container {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
