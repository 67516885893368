.page-header__section--notice {
  display: none;
}

.page-header__section-container {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.page-header__logo-image {
  display: block;
  height: 48px;
  width: auto;
}

@media (min-width: 768px) {
  .page-header__logo-image {
    height: 56px;
  }
}

@media (min-width: 1200px) {
  .page-header__section--main .page-header__section-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .page-header__logo-image {
    height: 68px;
  }

  .page-header__section--notice {
    display: block;
    background: #001e60;
    color: #FFF;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.2px;
  }

  .page-header__section--notice a,
  .page-header__section--notice a:visited {
    color: #e87722;
  }

  .page-header__section--notice a:hover,
  .page-header__section--notice a:active {
    color: #fc9d6f;
  }

  .page-header__section--notice .page-header__section-container {
    justify-content: flex-end;
  }
}
