.button {
  font-size: 1.125rem; /* 18px */
  line-height: 32px;
  display: inline-block;
  appearance: none;
  background: #d4431c;
  border: 0;
  padding: 4px 12px;
  margin: 0;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
}

.button:hover,
.button:active {
  background: #b42813;
}

a.button,
a.button:visited,
a.button:hover,
a.button:active {
  text-decoration: none;
  color: #fff;
}

@media (min-width: 768px) {
  .button {
    font-size: 1.5rem; /* 24px */
    line-height: 1.5;
    padding-left: 16px;
    padding-right: 16px;
  }
}
